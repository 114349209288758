<template>
  <div>
    <div style="display: flex; flex-wrap: wrap;">
      <div>
        <span>第三方平台：</span>
        <a-select class="search-input" ref="select" v-model="thirdPlatform">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="wxshop">小商店</a-select-option>
          <a-select-option value="dyshop">抖店</a-select-option>
          <a-select-option value="kwaishop">快手</a-select-option>
          <a-select-option value="xhs">小红书</a-select-option>
        </a-select>
      </div>
      <div>
        <span>绑定方式：</span>
        <a-select class="search-input" ref="select" v-model="isSku">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">SKU ID</a-select-option>
          <a-select-option :value="2">商品ID</a-select-option>
        </a-select>
      </div>
      <div>
        <span>绑定课程/会员名称：</span>
        <a-input v-model="businessName" class="search-input" style="width: 200px;" placeholder="请输入绑定课程/会员名称" />
      </div>
      <div>
        <span>第三方商品ID/SKU ID：</span>
        <a-input v-model="productId" class="search-input" style="width: 200px;"  placeholder="请输入第三方商品ID" />
      </div>
      <a-button icon="search" type="primary" style="margin-top: 10px;" @click="onSearch">搜索</a-button>
    </div>
    <a-table :columns="columns" :data-source="list" row-key="id" :pagination="false" :scroll="{ x: 1300 }">
      <span slot="action" slot-scope="text, record">
        <template>
          <a style="margin-right: 10px" @click="onEdit(record)">编辑</a>
          <a-popconfirm
          title="确定删除此记录？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delRelation(record)"
        >
          <a>删除</a>
        </a-popconfirm>
        </template>
      </span>
    </a-table>
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="pageCallback"
    ></MyPagination>
    <addBinding :visible="showDrawer" :id="curRecord.id" @close="onClose" />
  </div>
</template>
<script>
import { productBindingColumns } from '../constants'
import MyPagination from "@/components/pagination/MyPagination";
import { relationListAPI, delRelationAPI } from '@/request/api/orderformManage'
import addBinding from '../addBinding.vue';
export default {
  name: 'thirdBindList',
  components: { MyPagination, addBinding },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      columns: productBindingColumns,
      businessName: '',
      productId: '',
      skuID: '',
      isSku: 0,
      thirdPlatform:'',
      list: [],
      count: 0,
      pageNo: 1,
      pageSize: 20,
      showDrawer: false,
      curRecord: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async delRelation(record) {
      const params = {
        id: record.id
      }
      const { code } = await delRelationAPI(params)
      if (code !== 200) return
      this.$message.success('删除成功')
      this.pageNo = 1
      this.fetchData()
    },
    onSearch() {
      this.pageNo = 1
      this.fetchData()
    },
    async fetchData () {
      this.list = []
      this.count = 0
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        businessName: this.businessName,
        productId: this.productId,
        isSku: this.isSku,
        thirdPlatform: this.thirdPlatform,
        businessType: this.type
      }
      const { code, data } = await relationListAPI(params)
      if (code !== 200) return
      this.count = data.count
      this.list = data.list
    },
    pageCallback(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.fetchData()
    },
    onEdit(record) {
      this.showDrawer = true
      this.curRecord = record
      // this.$router.push(`/orderformManage/productBinding/addBinding/${record.id}`)
    },
    onClose(flag) {
      if (flag) this.fetchData()
      this.showDrawer = false
    }
  }
}
</script>
<style lang="less" scoped>
.search-input {
  width: 150px;
  margin-right: 20px;
}
</style>