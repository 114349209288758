<template>
  <div class="color_white">
    <!-- 二级 -->
    <div v-if="$route.meta.isShow">
      <div class="search-box">
        <a-button icon="plus" type="primary" @click="clickBtn">添加绑定</a-button>
      </div>
      <a-tabs :default-active-key="activeTab" :animated="false" @change="changeTab">
        <a-tab-pane key="1" tab="课程">
          <ThirdBindList :type="1" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="会员" force-render>
          <ThirdBindList :type="2" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view v-else />
     <addBinding :visible="showDrawer" :business-type="+activeTab" :id="'0'" @close="onClose" />
  </div>
</template>

<script>
import ThirdBindList from './modules/thirdBindList.vue'
import addBinding from './addBinding.vue';
export default {
  name: 'productBinding',
  components: { ThirdBindList, addBinding },
  data() {
    return {
      activeTab: '1',
      showDrawer: false
    }
  },
  
  methods: {
    changeTab(val) {
      this.activeTab = val
    },
    clickBtn() {
      this.showDrawer = true
    },
    onClose(flag) {
      this.showDrawer = false
    }
  }
}
</script>